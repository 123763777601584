const themeGen = theme => ({ // eslint-disable-line
  minWidth: 172,
  useFavourites: false,
  disableOuterBorders: true,
  disableInnerBorders: true,
  seoLevel: '2',
  enableOuterPadding: true,
});

export default themeGen;
