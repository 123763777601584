const themeGen = (theme) => {
  const res = {
    oneKrSticker: {
      rootPath: 'images/icons/',
      sticker: {
        se: '1krSticker.png',
        default: '1krSticker.png',
      },
    },
    conf: {
      showOneKronaSticker: true,
    },
  };
  return res;
};

export default themeGen;
