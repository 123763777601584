import loadable from '@loadable/component';
import loadWithSSRFallback from 'ui/Loader';
import { isPuppeteer } from 'core/util/extractStyles';
import { ErrorTypes, SpaError } from '@spa-ec/components/Error/Error';

const CategoryProductPage = loadWithSSRFallback(
  () => import('@spa-ec/routes/CategoryProductPage/CategoryProductPage'),
  'CategoryProductPage',
  {
    hydrate: isPuppeteer ? 'always' : 'delay',
    timeout: 350,
  }); // eslint-disable-line
const ContentPage = loadWithSSRFallback(() => import('@spa-ec/routes/ContentPage/ContentPage'), 'ContentPage');
const ProductDetailPage = loadWithSSRFallback(() => import('ec/routes/ProductDetailPage/ProductDetailPage.jsx'), 'ProductDetailPage'); // eslint-disable-line
const BrandsListingPage = loadWithSSRFallback(() => import('reco/BrandsListingPage/BrandsListingPage'), 'BrandsListingPage');
const ModelFinderPage = loadWithSSRFallback(() => import('reco/ModelFinderPage/ModelFinderPage'), 'ModelFinderPage');
const ModelShelfPage = loadWithSSRFallback(() => import('reco/ModelShelfPage/ModelShelfPage'), 'ModelShelfPage');

const ContactUsPage = loadable(() => import('reco/ContactUsPage/ContactUsPage.jsx'));
const SearchPage = loadable(() => import('reco/SearchPage/SearchPage'));
const OrderConfirmationPage = loadable(() => import('reco/OrderConfirmationPage/OrderConfirmationPage.jsx'));
const CheckoutPage = loadable(() => import('reco/CheckoutPage/CheckoutPage.jsx'));
const QliroCheckoutPage = loadable(() => import('@spa-ec/routes/QliroCheckoutPage/QliroCheckoutPage'));
const AccountPage = loadable(() => import('@spa-ec/routes/AccountPage/AccountPage'));
const LoginPage = loadable(() => import('@spa-ec/routes/LoginPage/LoginPage'));
const PasswordRequestPage = loadable(() => import('@spa-ec/routes/PasswordRequestPage/PasswordRequestPage'));
const PasswordChangePage = loadable(() => import('reco/PasswordChangePage/PasswordChangePage.jsx'));
const FavouriteProductsPage = loadable(() => import('reco/FavouriteProductsPage/FavouriteProductsPage.jsx'));
const CartPage = loadable(() => import('@spa-ec/routes/CartPage/CartPage'));
const CustomerFeedbackPage = loadable(() => import('@spa-ec/routes/CustomerFeedbackPage/CustomerFeedbackPage'));

const getComponentByType = (type) => {
  switch (type) {
    case 'STATIC_PAGE':
    case 'DEFAULT':
    case 'CONTENT':
    case 'CUSTOMER_FEEDBACK':
      return ContentPage;
    case 'SEARCH':
      return SearchPage;
    case 'PRODUCT_LISTING':
      return CategoryProductPage;
    case 'PRODUCT_DETAIL':
      return ProductDetailPage;
    case 'CART':
      return CartPage;
    case 'CHECKOUT':
      return CheckoutPage;
    case 'QLIRO_CHECKOUT':
      return QliroCheckoutPage;
    case 'ORDER_CONFIRMATION':
      return OrderConfirmationPage;
    case 'account':
    case 'ACCOUNT':
      return AccountPage;
    case 'LOGIN':
      return LoginPage;
    case 'BRANDS_LISTING':
      return BrandsListingPage;
    case 'MODEL_LISTING':
    case 'MODEL_FINDER':
      return ModelFinderPage;
    case 'MODEL_SHELF':
      return ModelShelfPage;
    case 'PASSWORD_CHANGE':
      return PasswordChangePage;
    case 'PASSWORD_REQUEST':
      return PasswordRequestPage;
    case 'CONTACT_US':
      return ContactUsPage;
    case 'FAVOURITES':
      return FavouriteProductsPage;
    default:
      return () => {
        throw new SpaError({ errorType: ErrorTypes.NOT_FOUND });
      };
  }
};

export default getComponentByType;
