import { TableColumn } from '../interfaces'

export enum ProductTableColumnId {
    PRODUCT_IMAGE = 'productImage',
    PRODUCT_CODE = 'productCode',
    PRODUCT_CORRESPONDS_TO = 'productCorrespondsTo',
    PRODUCT_MANUFACTURER = 'productManufacturer',
    PRODUCT_NAME = 'productName',
    STOCK = 'stock',
    PRICE = 'price',
    ADD_TO_CART_AND_SUBSCRIPTION = 'addToCartAndSubscription',
    PRODUCT_USAGE = 'productUsage',
    PAGE_CAPACITY = 'pageCapacity',
    ENVIRONMENTAL = 'environmental',
}

export const DEFAULT_PRODUCT_SHELF_COLUMNS: TableColumn[] = [
    {
        id: ProductTableColumnId.PRODUCT_IMAGE,
        classes: '',
        title: '',
        defaultTitle: '',
    },
    {
        id: ProductTableColumnId.PRODUCT_CODE,
        classes: '',
        title: 'product.code.short',
        defaultTitle: 'Produktnr',
    },
    {
        id: ProductTableColumnId.PRODUCT_CORRESPONDS_TO,
        classes: '',
        title: 'product.code.replaces',
        defaultTitle: '',
    },
    {
        id: ProductTableColumnId.PRODUCT_NAME,
        classes: '',
        title: 'word.product',
        defaultTitle: '',
    },
    {
        id: ProductTableColumnId.PRODUCT_USAGE,
        classes: '',
        title: 'product.tablerow.yield.title',
        defaultTitle: 'Innehåll',
    },
    {
        id: ProductTableColumnId.PAGE_CAPACITY,
        classes: '',
        title: 'product.tablerow.spec.text',
        defaultTitle: 'Kapacitet',
    },
    {
        id: ProductTableColumnId.PRODUCT_MANUFACTURER,
        classes: '',
        title: 'product.manufacturer',
        defaultTitle: '',
    },
    {
        id: ProductTableColumnId.ENVIRONMENTAL,
        classes: '',
        title: 'product.tablerow.environmental.title',
        defaultTitle: 'Miljö',
    },
    {
        id: ProductTableColumnId.STOCK,
        classes: '',
        title: 'product.variants.in.stock',
        defaultTitle: '',
    },
    {
        id: ProductTableColumnId.PRICE,
        classes: 'text-center',
        title: 'product.tablerow.price.title',
        defaultTitle: '',
    },
    {
        id: ProductTableColumnId.ADD_TO_CART_AND_SUBSCRIPTION,
        classes: 'text-right',
        title: '',
        defaultTitle: '',
    },
]
