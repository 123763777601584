// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
/* eslint-disable */
import Bubble from '~/packages/spa-ec/src/displayComponents/Bubble/Bubble.theme-dammsugarpasar.js';
import CartMod from '~/packages/spa-ec/src/components/Cart/CartMod.theme-dammsugarpasar.js';
import Cheapest from '~/packages/spa-ec/src/displayComponents/PrisjaktCheapest/Cheapest.theme-dammsugarpasar.js';
import ComponentsContainer from '~/packages/spa-ec/src/displayComponents/Cms/ComponentsContainer.theme-dammsugarpasar.js';
import ContactUsPage from '~/packages/spa-ec/src/routes/ContactUsPage/ContactUsPage.theme-dammsugarpasar.js';
import GDPRPopupMod from '~/packages/spa-ec/src/components/GDPRPopup/GDPRPopupMod.theme-dammsugarpasar.js';
import Images from '~/packages/spa-ec/src/components/ProductDetails/Images/themes/Images.theme-dammsugarpasar.js';
import MyPages from '~/packages/spa-ec/src/components/MyPages/MyPages.theme-dammsugarpasar.js';
import OrderConfirmation from '~/packages/spa-ec/src/components/OrderConfirmation/OrderConfirmation.theme-dammsugarpasar.js';
import OrderConfirmationPage from '~/packages/spa-ec/src/routes/OrderConfirmationPage/OrderConfirmationPage.theme-dammsugarpasar.js';
import OrderHistory from '~/packages/spa-ec/src/components/OrderHistory/OrderHistory.theme-dammsugarpasar.js';
import ProductGrid from '~/packages/spa-ec/src/displayComponents/ProductGrid/ProductGrid.theme-dammsugarpasar.js';
import ProductPromotionMod from '~/packages/spa-ec/src/components/ProductPromotion/ProductPromotionMod.theme-dammsugarpasar.js';
import ProductUpsellCarouselMod from '~/packages/spa-ec/src/components/ProductUpsell/ProductUpsellCarouselMod.theme-dammsugarpasar.js';
import Radio from '~/packages/ui-elem/src/Radio.theme-dammsugarpasar.js';
import RestoreCartPanel from '~/packages/spa-ec/src/components/RestoreCart/RestoreCartPanel.theme-dammsugarpasar.js';
import SearchList from '~/packages/spa-ec/src/components/Search/SearchList.theme-dammsugarpasar.js';
import SiteLogo from '~/packages/spa-ec/src/components/Header/HeaderSiteLogo/SiteLogo.theme-dammsugarpasar.js';
import StockStatus from '~/packages/spa-ec/src/components/StockStatus/StockStatus.theme-dammsugarpasar.js';
import USPItem from '~/packages/spa-ec/src/displayComponents/Cms/USPItem.theme-dammsugarpasar.js';
import USPWrapper from '~/packages/spa-ec/src/displayComponents/Cms/USPWrapper.theme-dammsugarpasar.js';

const overrides = {
  Bubble,
  CartMod,
  Cheapest,
  ComponentsContainer,
  ContactUsPage,
  GDPRPopupMod,
  Images,
  MyPages,
  OrderConfirmation,
  OrderConfirmationPage,
  OrderHistory,
  ProductGrid,
  ProductPromotionMod,
  ProductUpsellCarouselMod,
  Radio,
  RestoreCartPanel,
  SearchList,
  SiteLogo,
  StockStatus,
  USPItem,
  USPWrapper,
};
export default overrides;

// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
