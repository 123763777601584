const themeGen = (theme) => {
  const res = {
    conf: {
      iconInAddToCartButton: true,
    },
  };
  return res;
};

export default themeGen;
