const themeGen = theme => ({ // eslint-disable-line
  iconName: {
    BUYABLE: 'Check',
    NOT_BUYABLE: 'TimesCircle',
    PROLONGED_DELIVERY: 'Clock',
  },
  iconType: {
    BUYABLE: 'light',
    NOT_BUYABLE: 'solid',
    PROLONGED_DELIVERY: 'regular',
  },
  showIcon: true,
});

export default themeGen;
