const palette = {
    dammSkyBlue: '#d8e5ea',
    dammBlue: '#004b6a',
    dammBluePeer: '#003c54',
    dammBlueLight: '#799dae',
    dammBlueGray: '#9bc3c2',
    dammLightBlueGray: '#e3eeee',
    dammBlueLightV2: '#3d9cc1',
    dammBlueGrayV2: '#9fccdd',
    dammLightBlueGrayV2: '#d8e5ea',
    dammGreen: '#71bf44',
    dammGreenShade: '#65ac3d',
    pink: '#e8008c',
    blueInk: '#2e6f88',
    blueTint: '#f3fbfa',
    clearBlue: '#448ebf',
    splash: '#afdedf',
    splashPeer: '#d5f5f6',
    orange: '#e93e30',
    orangeHighlight: '#f05b49',
    blackGradient1: '#111',
    blackGradient2: '#222',
    blackGradient3: '#333',
    blackGradient4: '#525252',
    blackGradient6: '#666',
    blackGradient9: '#999',
    blackGradientb: '#b2b2b2',
    gray: '#cccecf',
    grayLight: '#ececec',
    grayDark: '#7f7f7f',
    offWhite: '#f4f4f4',
    inkySkyBlueShadeLight: '#deedf9',
};
const theme = {
    siteId: 'dammsugarpasar',
    palette,
    textColor: palette.blackGradient3,
    radius: '3px',
    col: {
        primary: {
            alt: 'white',
            fg: palette.dammGreenShade,
            peer: palette.dammGreen,
        },
        secondary: {
            alt: 'white',
            fg: palette.dammBlue,
            peer: palette.dammBluePeer,
        },
        tertiary: {
            alt: 'white',
            fg: '#343434',
            peer: palette.blackGradient4,
        },
        panel: {
            fg: palette.offWhite,
            peer: '#d4d4d4',
            alt: palette.blackGradient3,
        },
        splash: {
            fg: palette.splash,
            peer: palette.splashPeer,
            alt: palette.blackGradient3,
        },
        highlight: {
            fg: palette.dammBlueLightV2,
            peer: palette.blueInk,
            alt: 'white',
        },
        info: {
            alt: 'white',
            fg: '#6376ba',
            peer: '#5367aa',
        },
        error: {
            alt: '#f15c4a',
            fg: '#ffe5e6',
            peer: 'white',
        },
        pale: {
            alt: '#343434',
            fg: 'white',
            peer: palette.grayLight,
        },
        vars: {
            priceDiscountActive: palette.pink,
            priceDiscountOverruled: palette.blackGradient3,
            bodyBg: palette.dammSkyBlue,
        },
    },
    font: {
        size: {
            // /** @option {string} font.size.x3 */
            // x3: '1.8rem',
            // /** @option {string} font.size.x2 */
            // x2: '1.5em',
            // /** @option {string} font.size.x1 */
            // x1: '21px',
            // /** This is the default font size
            //  * @option {string} font.size.m */
            // m: '12px',
            // /** @option {string} font.size.s0 */
            // s0: '0.90rem',
            // /** @option {string} font.size.s1 */
            // s1: '0.80rem',
            // /** @option {string} font.size.s2 */
            // s2: '0.45rem',
            x4: '32px',
            x3: '24px',
            x2: '20px',
            x1: '18px',
            m: '16px',
            s1: '14px',
            s2: '12px',
            s3: '10px',
        },
        special: {
            family: 'georgia',
        },
        header: {
            family: 'Roboto, sans-serif',
        },
    },
    config: {
        uniformContentBlock: false,
    },
};
export default theme;
