export const NAME: string = 'ProductDetails'

export interface ProductDetailsTheme {
    productDetailsSeparator?: string
    borderSpecificationTable?: boolean
    gapSeparator?: boolean
    showCartIcon?: boolean
    isHPLeagalSpecialCase?: boolean
    productDetailsBoldness?: boolean
    applyPaddingSubscriptionPanel?: boolean
    showEnvironmentalCertificates?: boolean
}
