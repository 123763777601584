const themeGen = theme => ({ // eslint-disable-line
  cartIcon: 'shopping-basket',
  addToCartButton: false,
  rightMargin: false,
  imageHeight: '300px',
  showBorderOnThumbnail: true,
  applyMaxWidthOnImage: true,
  minThumbnailCount: 0,
  showCarouselOnDesktop: true,
});

export default themeGen;
